import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from '@routing/app.routing';
import { DashboardLayoutComponent, DashboardTopbarComponent, ModuleRouterLayoutComponent } from '@app/layout';
import { MainMenuComponent } from '@layout/dashboard-layout/main-menu/main-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { UiDemoComponent } from '@layout/ui-demo/ui-demo.component';
import { ngxTranslateConfig } from '@core/config/ngx-translate';
import { ErrorCatchingInterceptor } from '@core/interceptors';
import { TokenRefreshInterceptor } from '@core/interceptors/token-refresh.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { createPrimeNgProvider } from '../scss/theme/prime.preset';

const components = [AppComponent, DashboardLayoutComponent, DashboardTopbarComponent, MainMenuComponent, DashboardLayoutComponent, ModuleRouterLayoutComponent];

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/main/', '.json');
}

@NgModule({
    declarations: [...components, UiDemoComponent],
    exports: [],
    bootstrap: [AppComponent],
    imports: [RouterModule, BrowserModule, BrowserAnimationsModule, CoreModule, SharedModule, AppRoutingModule, TranslateModule.forRoot(ngxTranslateConfig)],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenRefreshInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorCatchingInterceptor, multi: true },
        provideAnimationsAsync(),
        provideHttpClient(withInterceptorsFromDi()),
        createPrimeNgProvider()
    ]
})
export class AppModule {}
