import { Component } from '@angular/core';
import { LanguageService } from '@core/services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
    constructor(private languageService: LanguageService) {
        this.initTranslationService();
    }

    protected initTranslationService() {
        this.languageService.initialLang();
    }
}
