import { definePreset } from '@primeng/themes';
import Material from '@primeng/themes/material';
import { providePrimeNG } from 'primeng/config';

export function createPrimeNgProvider() {
    return providePrimeNG({
        theme: {
            preset: EmpluPrimeTheme,
            options: {
                darkModeSelector: 'html[data-theme="dark"]' // Dark Mode nur aktiv, wenn explizit gesetzt
            }
        }
    });
}
const EmpluPrimeTheme = definePreset(Material, {
    components: {
        accordion: {
            header: {
                hover: {
                    background: 'rgba(242, 241, 241, 1)'
                }
            }
        },
        checkbox: {
            box: {
                background: 'transparent', // Transparenter Hintergrund, wenn nicht ausgewählt
                border: 'rgba(0, 0, 0, 1)' // Schwarzer Rand für bessere Sichtbarkeit
            },
            icon: {
                checked: {
                    color: '#000',
                    hover: {
                        color: '#fff'
                    } // Icon-Farbe wird schwarz
                }
            }
        }
    }
});

export default EmpluPrimeTheme;
